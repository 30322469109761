<template>
  <section class="container">
    <div class="row justify-content-center justify-content-md-between mb-3 mr-0 ml-2 ml-sm-0">
      <div class="col-auto px-0">
        <mdb-btn
          flat
          dark-waves
          icon="arrow-left"
          type="button"
          title="Regresar"
          @click="$router.go(-1)"
        >
          Regresar
        </mdb-btn>
      </div>
    </div>
    <div class="encabezado-detalles">
      <header class="h4-responsive text-center pt-3">Reporte</header>
      <div class="row mx-0 mt-2">
        <div class="col-12 col-md-4">
          <span class="small-block font-titulo">Nombre del reporte</span>
          <p>
            Pedidos vs Despacho
          </p>
        </div>
        <div class="col-12 col-sm">
          <span class="small-block font-titulo">Mes</span>
          <p>
            {{ fechaResumen }}
          </p>
        </div>
        <div class="col-12">
          <span class="small-block font-titulo">Descripción</span>
          <p class="mb-1">
            Reporte de los Pedidos vs Despacho
            <span v-if="!vendedoresFiltrados.length">de todos los vendedores.</span>
            <span v-else-if="vendedoresFiltrados.length >= 2" class="d-inline">
              de los vendedores:
              <span v-for="(vende, index) in vendedoresFiltrados" :key="index" class="text-capitalize">
                {{vende.nom_per + ' ' + vende.ape_per + (index === (vendedoresFiltrados.length -1) ? '. ' : ', ')}}
              </span>
            </span>
            <span v-else>
              del vendedor {{ vendedoresFiltrados[0].nom_per + ' ' + vendedoresFiltrados[0].ape_per }}.
            </span>
          </p>
          <p class="mb-1">Muestra la cantidad total de productos despachados en la fecha seleccionada, estén o no relacionados con los pedidos pertenecientes al mismo rango de fecha.</p>
          <p>Muestra la cantidad total de producto pedido en el rango de fecha seleccionada incluyendo pedidos en estado Incompleto, Completado y En cola.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3">
        <mdb-input
          v-model="fechaResumen"
          type="month"
          label="Mes"
          class="my-2"
          outline
        />
      </div>
      <div class="col-12 col-sm col-md-4 col-xl-3">
        <mdb-input
          class="my-2"
          v-model="fechaDel"
          type="date"
          label="Desde"
          outline
        />
      </div>
      <div class="col-12 col-sm col-md-4 col-xl-3">
        <mdb-input
          class="my-2"
          v-model="fechaHasta"
          type="date"
          label="Hasta"
          outline
        />
      </div>
      <div class="col-12 col-md-6 col-xl-3 px-0">
        <FiltrosTipo1
          :ven="vendedoresFiltro"
          :responsive="responsive"
          :restablecer-filtrado="restablecer"
          :mostrar-btn-restablecer="false"
          @vendedorAFiltrar="vendedoresAFiltrar = $event; actualizar()"
        />
      </div>
      <div
        v-if="fechaHasta.length && fechaDel.length || vendedoresAFiltrar.length"
        class="col-12 col-md text-center"
      >
        <mdb-btn
          flat
          dark-waves
          icon="broom"
          class="my-2 px-1"
          @click="restablecerFiltros()"
        >
          Restablecer filtros
        </mdb-btn>
      </div>
    </div>
    <div
      v-if="cargando"
      class="mensaje-no-items py-3"
    >
      <LoaderKel />
      <p class="texto mt-2">
        Cargando
      </p>
    </div>
    <!-- Mensaje cuando el vendedor seleccionado no tiene pedido -->
    <div
      v-else-if="!pedidos.length"
      class="mensaje-no-items pb-3"
    >
      <font-awesome-icon
        icon="clipboard-list"
        size="5x"
        class="icono"
      />
      <p class="texto">No hay pedidos según los parámetros seleccionados</p>
    </div>
    <ul
      v-else
      class="table despachos mt-4"
    >
      <li class="encabezado sticky">
        <div class="row mx-0">
          <div class="col-12 col-sm px-0">
            <header class="h4-responsive">Pedidos vs Despacho</header>
          </div>
          <mdb-btn
            flat
            dark-waves
            icon="print"
            class="col-12 col-sm-3 col-md-2 px-3 py-2 mx-sm-0 mr-md-3 ml-md-2"
            @click="imprimirTablaPedidosvsDespacho(pedidos,totales,fechaResumen)"
          >
            Imprimir
          </mdb-btn>
        </div>
        <div class="thead claro">
          <div class="col-12 col-sm col-lg-4">
            Producto - Descripción
            <span class="small-block">
              Código - Categoría
            </span>
          </div>
          <div class="col-12 col-sm col-md-2 col-lg-3 text-sm-right">
            Pedido
            <span class="small-block">
              # Kilo
            </span>
          </div>
          <div class="col-12 col-sm col-md-2 col-lg-3 text-sm-right">
            Despacho
            <span class="small-block">
              # Kilo
            </span>
          </div>
          <div class="col-12 col-sm col-md-2 text-sm-right">
            Pedidos vs
            <span class="small-block">
              Despacho
            </span>
          </div>
        </div>
      </li>
      <li
        class="contenido"
        v-for="p in pedidos"
        :key="p.id_pre"
      >
        <div
          class="col-12 col-sm col-lg-4"
          data-columna="Producto - Descripción / Código - Categoría"
        >
          <p>
            {{p.nom_pro}} -
            <span class="d-block d-sm-inline">
              {{p.des_pre}}
            </span>
            <span class="small-block">
              {{p.cod_pre}} - {{p.nom_cat}}
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Pedido / # Kilo"
        >
          <p>
            {{parseFloat(p.cantidadPedida).toLocaleString('es')}}
            <span class="small-block">
              {{parseFloat(p.pesoPedido).toLocaleString('es')}} kg
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Despacho / # Kilo"
        >
          <p>
            {{p.cantidadDespachada}}
            <span class="small-block">
              {{p.pesoDespachado}} kg
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-2 celda-numerica"
          data-columna="Pedidos vs Despacho"
        >
          <p>
            {{p.porcentajeCumplimiento}}
          </p>
        </div>
      </li>
      <li class="contenido claro">
        <div
          class="col-12 col-sm col-lg-4"
          data-columna="Totales"
        />
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Pedido / # Kilo"
        >
          <p>
            {{Number(totales.pedido).toLocaleString('es')}}
            <span class="small-block">
              {{ Number((totales.pesoPedido).toFixed(2)).toLocaleString('es') }} kg
            </span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-3 celda-numerica"
          data-columna="Despacho / # Kilo"
        >
          <p>
            {{totales.despachado}}
            <span class="small-block">{{ Number((totales.pesoDespachado).toFixed(2)) }} kg</span>
          </p>
        </div>
        <div
          class="col-12 col-sm col-md-2 col-lg-2 celda-numerica"
          data-columna="Pedidos vs Despacho"
        >
          <p>
            {{isFinite(Number((totales.despachado/totales.pedido*100).toFixed(2)))
              ? Number((totales.despachado/totales.pedido*100).toFixed(2))+' %'
              : '0'
            }}
            <span class="small-block">
              {{isFinite(Number((totales.pesoDespachado/totales.pesoPedido*100).toFixed(2)))
                ? Number((totales.pesoDespachado/totales.pesoPedido*100).toFixed(2))+' % (kg)'
                : '0'
              }}
            </span>
          </p>
        </div>
      </li>
    </ul>
    <OpcionesImpresion
      :mostrar-modal="opcionesDeImpresion.mostrarModal"
      :codigoDeImpresion="opcionesDeImpresion.codigoDeImpresion"
      :nombre-documento="opcionesDeImpresion.nombreDocumento"
      @cerrar="opcionesDeImpresion.mostrarModal = $event"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import { mdbInput, mdbBtn } from 'mdbvue'
import OpcionesImpresion from '@/components/OpcionesImpresion.vue'
import { PedidosvsDespacho } from '@/funciones/formatosImpresion.js'
import FiltrosTipo1 from '@/components/FiltrosTipo1.vue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import { apiPost } from '@/funciones/api.js'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'PedidosVsDespacho',
  components: {
    mdbInput,
    mdbBtn,
    OpcionesImpresion,
    FiltrosTipo1,
    AlertaMensaje,
    LoaderKel
  },
  data () {
    return {
      responsive: { vendedor: 'col-12' },
      fechaResumen: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2),
      fechaDel: '',
      fechaHasta: '',
      vendedoresFiltro: [],
      vendedoresAFiltrar: [],
      vendedoresFiltrados: [],
      pedidos: [],
      totales: {
        pedido: 0,
        despachado: 0,
        pesoPedido: 0,
        pesoDespachado: 0
      },
      opcionesDeImpresion: {
        codigoDeImpresion: [],
        mostrarModal: false,
        nombreDocumento: ''
      },
      cargando: false,
      restablecer: false,
      alertaMensaje: { contenido: '' }
    }
  },
  mounted () {
    this.actualizar()
    this.obtenerVendedoresFiltros()
  },
  watch: {
    fechaResumen () {
      this.fechaDel = ''
      this.fechaHasta = ''
      this.actualizar()
    },
    fechaHasta () {
      if (!this.fechaHasta) return
      this.actualizar()
    },
    fechaDel () {
      if (!this.fechaHasta) return
      this.actualizar()
    },
    vendedoresAFiltrar () {
      this.vendedoresFiltrados = []
      this.vendedoresSeleccionados()
    }
  },
  methods: {
    vendedoresSeleccionados () {
      this.vendedoresAFiltrar.forEach(vende => {
        this.vendedoresFiltro.forEach(vendefiltrado => {
          return vendefiltrado.id_per === vende ? this.vendedoresFiltrados.push(vendefiltrado) : null
        })
      })
    },
    restablecerFiltros () {
      this.restablecer = true
      this.fechaDel = ''
      this.fechaHasta = ''
      this.vendedoresAFiltrar = []
      this.vendedoresFiltrados = []
      this.fechaResumen = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2)
      this.actualizar()
      setTimeout(() => {
        this.restablecer = false
      }, 500)
    },
    obtenerVendedoresFiltros () {
      apiPost({ s: 'vendedores' }, '')
        .then((res) => {
          this.vendedoresFiltro = res.data.ven
        }).catch(() => {
          this.alertaMensaje = {
            contenido: 'Ocurrió un error obteniendo los clientes',
            tipo: 'error'
          }
        })
    },
    actualizar () {
      this.cargando = true
      let data = this.fechaResumen && this.fechaResumen.length && `y=${this.fechaResumen.split('-')[0]}&m=${this.fechaResumen.split('-')[1]}`
      data += this.fechaHasta ? '&del=' + this.fechaDel + '&al=' + this.fechaHasta : ''
      this.vendedoresAFiltrar && this.vendedoresAFiltrar.length && (data += `&vendedores=${this.vendedoresAFiltrar.join(',')}`)
      apiPost({ s: 'pedvsdesM' }, data)
        .then((r) => {
          this.totales = {
            pedido: 0,
            despachado: 0,
            pesoPedido: 0,
            pesoDespachado: 0
          }
          this.pedidos = r.data.ele.map((e) => {
            const cantidadDespachada = Number(e.can_ped_np) - Number(e.can_np)
            const pesoPedido = Number((e.can_ped_np * e.pesm_pre).toFixed(2))
            const pesoDespachado = Number((cantidadDespachada * e.pesm_pre).toFixed(2))
            this.totales.pedido += Number(e.can_ped_np)
            this.totales.despachado += cantidadDespachada
            this.totales.pesoPedido += Number(pesoPedido)
            this.totales.pesoDespachado += Number(pesoDespachado)
            return {
              nom_pro: e.nom_pro,
              des_pre: e.des_pre,
              cod_pre: e.cod_pre,
              nom_cat: e.nom_cat,
              cantidadPedida: e.can_ped_np,
              cantidadDespachada,
              pesoPedido,
              pesoDespachado,
              can_ped_np: e.can_ped_np,
              pesm_pre: e.pesm_pre,
              porcentajeCumplimiento: isFinite(((e.can_ped_np - e.can_np) / e.can_ped_np * 100).toFixed(2)) ? Number(((e.can_ped_np - e.can_np) / e.can_ped_np * 100).toFixed(2)) + '%' : ''
            }
          })
          this.cargando = false
        })
    },
    imprimirTablaPedidosvsDespacho (pedidos, totales, fechaResumen) {
      // Estableciendo la estructura para la tabla de pedidos
      const PEDIDOS = [
        // Fila de encabezados
        [
          {
            stack: [
              'PRODUCTO, DESCRIPCIÓN',
              {
                text: 'CÓDIGO, CATEGORÍA',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PEDIDO',
              {
                text: '#KILO',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'DESPACHO',
              {
                text: '#KILO',
                fontSize: 8
              }
            ],
            bold: true
          },
          {
            alignment: 'right',
            stack: [
              'PEDIDO VS',
              {
                text: 'DESPACHO',
                fontSize: 8
              }
            ],
            bold: true
          }
        ]
      ]

      for (const pedido in pedidos) {
        PEDIDOS.push([
          {
            stack: [
              `${pedidos[pedido].nom_pro}, ${pedidos[pedido].des_pre}`,
              {
                text: `${pedidos[pedido].cod_pre}, ${pedidos[pedido].nom_cat}`,
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              pedidos[pedido].cantidadPedida,
              {
                text: `${pedidos[pedido].pesoPedido} KG `,
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            stack: [
              pedidos[pedido].cantidadDespachada,
              {
                text: `${pedidos[pedido].pesoDespachado} KG `,
                fontSize: 8
              }
            ]
          },
          {
            alignment: 'right',
            text: pedidos[pedido].porcentajeCumplimiento,
            fontSize: 10
          }
        ])
      }
      PEDIDOS.push([
        {
          text: 'TOTALES',
          bold: true
        },
        {
          alignment: 'right',
          stack: [
            `${totales.pedido}`,
            {
              text: `${Number((totales.pesoPedido).toFixed(2))}`,
              fontSize: 8
            }
          ]
        },
        {
          alignment: 'right',
          stack: [
            `${totales.despachado}`,
            {
              text: `${Number((totales.pesoDespachado).toFixed(2))}`,
              fontSize: 8
            }
          ]
        },
        {
          alignment: 'right',
          stack: [
            `${isFinite(Number((totales.despachado / totales.pedido * 100).toFixed(2)))
              ? Number((totales.despachado / totales.pedido * 100).toFixed(2)) + ' %'
              : ''}`,
            {
              text: `${isFinite(Number((totales.pesoDespachado / totales.pesoPedido * 100).toFixed(2)))
                ? Number((totales.pesoDespachado / totales.pesoPedido * 100).toFixed(2)) + ' % (kg)'
                : '0'}`,
              fontSize: 8
            }
          ]
        }
      ])

      // Establece vendedor/es seleccionado/s
      let vendedores = []
      const listaVendedores = this.vendedoresFiltro
      if (this.vendedoresAFiltrar.length) {
        vendedores = this.vendedoresAFiltrar.map(idVenedor => {
          const vendedor = listaVendedores.find(vendedor => vendedor.id_per === idVenedor)
          return `${vendedor.nom_per} ${vendedor.ape_per}`
        })
      }
      const { nombre, formato } = PedidosvsDespacho(vendedores, fechaResumen, PEDIDOS)

      this.opcionesDeImpresion = {
        codigoDeImpresion: formato,
        nombreDocumento: nombre,
        mostrarModal: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table.despachos {
  // Define los breakpoints para el responsive de ul.table
  @include ul-table-responsive-at ("576px");
}
.boton-ocultar {
  @media screen and (min-width: 1660px) {
    display: none;
  }
}
</style>
